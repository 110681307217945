.user-text {
  font: normal normal 400 10px/22px Inter, sans-serif;
  letter-spacing: 0.28px;
  color: #000000;
  opacity: 1;
}

.user-row {
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #ededf0;
}
.word-align {
  word-break: break-all;
}

.cancel-edit {
  font: normal normal 400 12px/50px Inter, sans-serif;
  letter-spacing: 0px;
  color: #0073ff;
  cursor: pointer;
}

.edit-confirm-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #00d56e19 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
}

.edit-confirm-icon {
  color: #00d56e;
}

.password-instructions {
  font: normal normal 400 12px/20px Inter, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.option-label {
  font: normal normal 400 12px/21px Inter, sans-serif;
  letter-spacing: 0px;
  color: #7c7c7c;
  opacity: 1;
}
