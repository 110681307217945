.building-name {
  font: normal normal 500 18px/24px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.floor-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}

.floor-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid #979fa5;
  padding: 10px;
}

.add-floor-icon {
  color: #0059b2;
  cursor: pointer;
}

.floor-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 8px;
  background-color: #f0f0f0;
  justify-content: space-between;
  align-items: center;
}

.floor-image-box {
  width: 30%;
  height: 70px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.floor-image-box .icon {
  font-size: 40px;
  color: #0059b2;
}

.floor-details {
  display: flex;
  flex-direction: column;
}

.floor-details .name {
  color: #000;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-transform: uppercase;
}

.floor-details .icon {
  color: #0059b2;
  font-size: 18px;
}

.floor-details .description {
  color: #475057;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.floor-details .total-floors {
  color: #475057;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.floors-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: #e5eef7;
  padding: 1rem;
  gap: 0.5rem;
}

.floor-selected {
  background-color: #0059b2;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e5eef7;
  border: 2px solid #0059b2;
}

.floor-default {
  background-color: #e5eef7;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border: 2px solid #0059b2;
}
