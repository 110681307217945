.sas-provider {
  background: #f2f2f5 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}

.sas-provider-active {
  background: #e5eef7 0% 0% no-repeat padding-box;
  border: 1px solid #0059b2;
  border-radius: 12px;
  opacity: 1;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}

.avatar {
  height: 100px;
  width: 100px;

  position: absolute;
}

.profile-pic label {
  position: absolute;
  height: 100px;
  width: 100px;
  cursor: pointer;
  z-index: 9;
}

.profile-pic input {
  display: none;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-pic {
  position: relative;
  height: 100px;
  width: 100px;

  .upload-overlay {
    position: absolute;
    background-color: transparent;
    color: white;
    height: 100%;
    width: 100%;
    .upload-button {
      height: 50%;
      justify-content: center;
      align-items: center;
      background-color: black;
      transform: translateY(100%);
      text-align: center;
      opacity: 70%;
      font-weight: 700;
      display: flex;
      border-radius: 0 0 6px 6px;
    }
    p {
      opacity: 100%;
    }
  }
}

.provider-selection-input {
  border-radius: 30px !important;
  background-color: white !important;
}

.provider-selection-heading {
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.lable-d{
  font : normal normal 400 12px/22px Inter ,serif;
  color: #000000E0;
}