.asset-name {
  font: normal normal 500 18px/24px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.asset-description {
  font: normal normal 300 12px/20px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.value-title {
  font: normal normal 400 12px/16px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.value-label {
  font: normal normal 400 12px/16px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.active-tab {
  /* background: #0059b219 0% 0% no-repeat padding-box; */
  /* border-radius: 20px; */
  opacity: 1;
  padding: 10px 20px;
  font: normal normal 600 12px/16px Inter ,sans-serif;
  letter-spacing: 0.28px;
  color: #1677FF;
  border-bottom: 1px solid #1677FF;
  /* text-transform: uppercase; */
}

.inactive-tab {
  padding: 10px 20px;
  font: normal normal 500 12px/16px Inter ,sans-serif;
  letter-spacing: 0.28px;
  color: #8f9fac;
  /* text-transform: uppercase; */
  opacity: 1;
}
.bg-card{
  background-color: #f5f5f5;
}
