.small-secondary-button {
    background:  #0059b21a 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 6px 12px;
    font: normal normal 400 8px/14px Inter ,sans-serif;
    letter-spacing: 0px;
    color: #0059b2;
  }
  
  .small-secondary-button-disabled {
    background: #ededf0 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    padding: 6px 12px;
    font: normal normal 400 8px/14px Inter ,sans-serif;
    letter-spacing: 0px;
    color: #9b9b9b;
  }
  
  .small-secondary-button .icon {
    color:  #0059b2;
    font-size: 12px;
  }

  .small-secondary-button-disabled .icon {
    color: #9b9b9b;
    font-size: 12px;
  }

  .small-secondary-button-disabled span {
    user-select: none;
  }

  .small-secondary-button span {
    user-select: none;
  }