.asset-image-title {
  font-size: 12px;
  font-family: Inter, sans-serif;
  color: #000;
  opacity: 0.7;
  text-transform: uppercase;
  font-weight: 600;
}

.image-label {
  font: normal normal 400 12px/20px Inter, sans-serif;
  color: #737373;
}

.img-uploaded {
  border-radius: 8px;
  background-color: #e2fce2;
  outline: none;
  border: 1px solid #8f9fac;
  cursor: pointer;
  height: 56px;
  min-height: 56px;
}

.img-default {
  border-radius: 8px;
  border: 1px dashed #8f9fac;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  height: 56px;
  min-height: 56px;
}
