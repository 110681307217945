.net-search-label-selected {
  color: #0059b2;
  cursor: pointer;
  font: normal normal 500 10px/22px Inter ,sans-serif !important;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 5px;
}

.net-search-label {
  color: #aaaaaa;
  cursor: pointer;
  font: normal normal 500 10px/22px Inter ,sans-serif !important;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 5px;
}

.net-action-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  height: 50px;
}

.net-action-box:focus-within {
  outline: 0.5px solid #0059b2 !important;
}

.net-search-input {
  opacity: 1;
  height: 26px;
  padding: 0px 12px;
  appearance: none;
  font: normal normal 400 12px/16px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #000000;
  border: none;
}

.net-search-input:focus {
  outline: none !important;
}

.network-icon {
  color: #aaaaaa;
  opacity: 1;
  cursor: pointer;
  font-size: 20px;
}

.filter-text {
  font: normal normal 400 14px/16px Inter ,sans-serif !important;
  color: #353435;
  opacity: 1;
}

.filter-label {
  font: normal normal 400 14px/16px Inter ,sans-serif !important;
  color: #9b9b9b !important;
  opacity: 1;
}

.filter-input-box,
.filter-input-box:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 12px;
  opacity: 1;
  box-shadow: none;
  height: 48px !important;
  width: 220px !important;
}

.sub-heading-network {
  font: normal normal 500 10px/22px Inter ,sans-serif !important;
  letter-spacing: 0.24px;
  color: #aaaaaa;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 5px;
}

.vertical {
  border-left: 2px dotted #cccccc;
  margin: 8px;
}

.selected-view {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 400 12px/22px Inter ,sans-serif;
  letter-spacing: 0.28px;
  color: #1677FF;
  padding: 8px 8px;
  text-transform: uppercase;
  /* border-bottom: 3px solid #0059b2; */
  /* transition: 0.3s; */
  cursor: pointer;
  padding: 5px 15px;
}

.unselected-view {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 400 12px/22px Inter ,sans-serif;
  letter-spacing: 0.28px;
  color: #000000E0;
  padding: 8px 8px;
  text-transform: uppercase;
  /* transition: 0.02s; */
  cursor: pointer;
  padding: 5px 15px;

}

.count-bubble {
  background-color: #fff;
  color: #000000;
  border-radius: 50px;
  /* padding: 0px 10px; */
  margin-left: 12px;
  border: 1px solid #D5D6D7;
  height: 22px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-buttons {
  width: 110px;
}

/* Doughnut chart css */
.doughnutchart {
  width: 62%;
}

.innerText {
  display: flex;
  justify-content: center;
  margin-top: -107px;
  font-weight: 600;
}

.text-primary {
  color: #F59243 !important;
}

.text-secondary {
  color: #E3472B !important;
}

.chartlabel {
  border: 1px solid #4091FC;
  width: 57px;
  border-radius: 30px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  height: 18px;
  background-color: #4091FC;
  color: white;
}

.fw-bold {
  font-weight: 500 !important;
}

.network-search {
  position: absolute;
  width: 305px;
  background-color: #ffffff;
  z-index: 99999;
  left: 30px;
  max-height: calc(75vh);
  overflow: hidden scroll;
  margin: 10px 0px;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 3px 10px 0px #00000052;
}

.search-list {
  margin: 5px 0px 5px 5px;
  padding: 0px 0px 5px 0px;
  border: 2px solid #ededef;
  border-radius: 8px;
  cursor: pointer;
}

.search-list:hover {
  background-color: #ededef;
}

.asset-image {
  height: 10px;
  width: 10px;
}

.netactid {
  font-size: 12px;
  font-weight: 700;
  color: #3c4043;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
}

.netactdetail {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  color: #3c4043;
  opacity: 1;
  padding-left: 5px;
}

.center-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar {
  width: 360px !important;
}

.card-content {
  display: contents;
}

.selected-chart-btn {
  background-color: #4091FC;
  color: white;
}

.unselected-chart-btn {
  background-color: white;
  color: #4091FC;
}
.assetnav-main{
  margin-left: 54px;
}

@media (max-width: 1200px) and (min-width: 550px){

  .zero-five-btn{
    margin-left: -2% !important;
  }
  .five-ten-btn{
    margin-left: 11% !important;
  }
  .ten-fifteen-btn{
    margin-left: 10% !important;
  }
  .gt-twenty-btn{
    margin-left: 7% !important;
  }

  .aerial-btn{
    margin-top: 90% !important;
  }

  .roe-btn{
    margin-top: 51% !important;
  }

  .smb-btn{
    margin-top: 53% !important;
  }
}


@media (max-width: 1050px) and (min-width: 300px){

  .zero-five-btn{
    margin-left: -2% !important;
  }
  .five-ten-btn{
    margin-left: 6% !important;
  }
  .ten-fifteen-btn{
    margin-left: 7% !important;
  }
  .gt-twenty-btn{
    margin-left: 3% !important;
  }

  .aerial-btn{
    margin-top: 91% !important;
  }

  .roe-btn{
    margin-top: 47% !important;
  }

  .smb-btn{
    margin-top: 45% !important;
  }
}


@media screen and (min-width: 1400px){

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 14% !important;
  }
  .ten-fifteen-btn{
    margin-left: 13% !important;
  }
  .gt-twenty-btn{
    margin-left: 11% !important;
  }
  .aerial-btn{
    margin-top: 79% !important;
  }

  .roe-btn{
    margin-top: 66% !important;
  }

  .smb-btn{
    margin-top: 68% !important;
  }
}

@media screen and (min-width: 1600px) {
  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 15% !important;
  }
  .ten-fifteen-btn{
    margin-left: 16% !important;
  }
  .gt-twenty-btn{
    margin-left: 12% !important;
  }

  .aerial-btn{
    margin-top: 77% !important;
  }

  .roe-btn{
    margin-top: 71% !important;
  }

  .smb-btn{
    margin-top: 72% !important;
  }

  .doughnutchart {
    width: 54% !important;
  }
}


@media screen and (min-width: 1900px){

  .chartlabel {
    width: 66px !important;
    border-radius: 17px !important;
    font-size: 12px !important;
    height: 23px !important;
    padding: 2px;
  }

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 16% !important;
  }
  .ten-fifteen-btn{
    margin-left: 16% !important;
  }
  .gt-twenty-btn{
    margin-left: 12% !important;
  }
  .aerial-btn{
    margin-top: 68% !important;
  }

  .roe-btn{
    margin-top: 75% !important;
  }

  .smb-btn{
    margin-top: 74% !important;
  }
}


@media screen and (min-width: 2000px){

  .chartlabel {
    width: 66px !important;
    border-radius: 17px !important;
    font-size: 12px !important;
    height: 23px !important;
  }

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 17% !important;
  }
  .ten-fifteen-btn{
    margin-left: 17% !important;
  }
  .gt-twenty-btn{
    margin-left: 16% !important;
  }
  .aerial-btn{
    margin-top: 67% !important;
  }

  .roe-btn{
    margin-top: 81% !important;
  }

  .smb-btn{
    margin-top: 78% !important;
  }
}


@media screen and (min-width: 2400px){

  .chartlabel {
    width: 66px !important;
    border-radius: 17px !important;
    font-size: 12px !important;
    height: 23px !important;
    padding: 2px;
  }

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 19% !important;
  }
  .ten-fifteen-btn{
    margin-left: 18% !important;
  }
  .gt-twenty-btn{
    margin-left: 17% !important;
  }
  .aerial-btn{
    margin-top: 67% !important;
  }

  .roe-btn{
    margin-top: 82% !important;
  }

  .smb-btn{
    margin-top: 83% !important;
  }

}


@media (max-width: 1800px) and (min-width: 1700px) {

  .chartlabel {
    border-radius: 17px !important;
    font-size: 11px !important;
    height: 23px !important;
    padding: 2px;
  }

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 17% !important;
  }
  .ten-fifteen-btn{
    margin-left: 16% !important;
  }
  .gt-twenty-btn{
    margin-left: 13% !important;
  }

  .aerial-btn{
    margin-top: 74% !important;
  }

  .roe-btn{
    margin-top: 68% !important;
  }

  .smb-btn{
    margin-top: 70% !important;
  }
}


@media (max-width: 1600px) and (min-width: 1500px){

  .zero-five-btn{
    margin-left: 10% !important;
  }
  .five-ten-btn{
    margin-left: 15% !important;
  }
  .ten-fifteen-btn{
    margin-left: 14% !important;
  }
  .gt-twenty-btn{
    margin-left: 12% !important;
  }

  .aerial-btn{
    margin-top: 77% !important;
  }

  .roe-btn{
    margin-top: 71% !important;
  }

  .smb-btn{
    margin-top: 72% !important;
  }

  .doughnutchart {
    width: 54% !important;
  }
}

/** user dropdown btn*/
.rs-btn {
  width: 148px !important;
  height: 48px !important;
  background-color: transparent !important;
  border:1px solid #cccccc;
  border-radius: 6px;
  padding-left: 10px !important;
  display: flex !important;
  padding-top: 13px !important;
  color: #373637 !important;
  font-size: 12px !important;
}

.rs-dropdown {
  border-radius: 12px;
}

/** user dropdown menu*/
.userdropdown {
  overflow-y: scroll;
  max-height: 10rem;
  width: 148px;
}

.daystext {
  width: 10%;
  margin-top: 42%; 
  color: #908F8D;
  font-size:11px;
}

/** Dropdown arrow style for user dropdown*/
.rs-icon {
  top: 14px !important;
  font-size: 8px !important;
}

.menuStyle {
  width:148px;
  margin-left: 16px;
}
.menuStyle .rs-dropdown-toggle.rs-btn-lg {
  padding-right: 0 !important;
}
.menuStyle button {
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px !important;
  background-color: #fff !important;
}
.menuStyle button svg {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  top : 0 !important;
}

.zero-five-btn {
  margin-left: 10%;
}

.five-ten-btn {
  margin-left: 12%;
}

.ten-fifteen-btn {
  margin-left: 11%;
}

.gt-twenty-btn {
  margin-left: 9%;
}

.aerial-btn {
  margin-top: 82%;
}

.roe-btn {
  margin-top: 60%;
}

.smb-btn {
  margin-top: 61%;
}

.dropdown-search {
  display: flex;
  border: 1px solid;
  width: 118px;
  border-radius: 6px;
  background-color: white;
}

.search-input-box:focus {
  outline: none;
}

.search-input-box {
  width: 83px;
  border: 1px;
  color: #3B3A3B;
}

.backdrop-color {
  background-color: #f9fafc;
}

.dd-align {
  right: 200%;
  margin-left: -295px;
  box-shadow: var(--rs-dropdown-shadow);
  position: relative;
  background-color: #fff;
}