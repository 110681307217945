.upload-selected-view {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 500 10px/16px Inter ,sans-serif;
    letter-spacing: 0.28px;
    color: #0059b2;
    padding: 6px 6px;
    border-bottom: 3px solid #0059b2;
    cursor: pointer;
  }
  
  .upload-unselected-view {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 500 10px/16px Inter ,sans-serif;
    letter-spacing: 0.28px;
    color: #aaaaaa;
    padding: 4px 4px;
    cursor: pointer;
  }

  .upload-vertical {
    border-left: 2px dotted #cccccc;
    margin: 3px;
  }
  
  