.floor-name {
  font: normal normal 500 18px/24px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.floor-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}
