.result-box {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #D5D6D7;
  padding: 8px 16px;
}

.result-label {
  font: normal normal 600 34px/43px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000E0;
  opacity: 1;
  padding: 8px 0 4px 0;
}

.result-title {
  font: normal normal 400 12px/18px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000E0;
  opacity: 1;
  text-align: left;
  margin-right: auto;
  line-height: 20px;
}
