.img-floor-uploaded {
  border-width: 2px;
  border-radius: 12px;
  border-color: #00d56e;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 12px/16px Inter, sans-serif !important;
  letter-spacing: 0px;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 54px;
}

.img-floor {
  border-width: 2px;
  border-radius: 12px;
  border-color: #cccccc;
  border-style: dashed;
  font: normal normal 400 12px/16px Inter, sans-serif !important;
  background-color: #fff;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 54px;
}

// .mapboxgl-canvas-container {
//   .mapboxgl-canvas {
//     // height: 100vh;
//     height: 100%;
//     width: 100%;
//   }
// }

.draw-floor-plan-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid #1677FF;
  padding: 10px;

  .draw-floor-plan-info {
    font-size: 28px;
  }
}

