.accordion-input-label {
  font: normal normal 400 12px/24px Inter ,sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.accordion-input {
  border-radius: 0px;
  font: normal normal 300 12px/24px Inter ,sans-serif;
  letter-spacing: 0px;
  /* color: #000000; */
  box-shadow: none;
  border-radius: 6px;
  opacity: 1;
}

.accordion-input:focus {
  outline: none;
  box-shadow: none;
}

.accordion-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  color: #9b9b9b;
}
