.search-input {
  background: none !important;
  border: none !important;
  font: normal normal 400 12px/22px Inter, sans-serif;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.search-input:focus {
  outline: none !important;
}

.address-list-row {
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
}

.search-result {
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.search-subresult {
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #8f9fac;
  opacity: 1;
}

.asset-image {
  height: 10px;
  width: 10px;
}

.venue-icon {
  height: 15px;
  width: 15px;
}

ul.search li {
  padding: 0px;
}
.search-list-new {
  background-color: #F5F5F5;
}
.border-no-top{
  border-top: none !important;
  border-radius: 0 !important;
}
.nav-link.active,.nav-link.active:focus {
  color: #FFFFFF !important;
  background-color: #1677FF !important;
  border-radius: 4px !important;
  text-decoration: none !important;
}
