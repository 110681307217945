.venue-name {
  font: normal normal 500 18px/24px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.entity-title {
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.entity-label {
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.building-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #000000;
}

.add-icon {
  color: #0059b2;
  cursor: pointer;
}

.building-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 12px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border: 1px solid #D5D6D7;
  max-height: 310px;
}

.building-card:hover {
  background-color: #91c2f31a;
}

.building-image-box {
  width: 30%;
  height: 122px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.building-image-box .icon {
  font-size: 38px;
  color: #0059b2;
}

.building-details {
  display: flex;
  flex-direction: column;
}
.name {
  font-weight: 600;
}
.building-details .name {
  color: #000;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-transform: uppercase;
}

.building-details .icon {
  color: #0059b2;
  font-size: 18px;
}
.description {
  word-wrap: break-word;
}
.building-details .description {
  color: #475057;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.building-details .total-floors {
  color: #475057;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.field-tech-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1677FF;
  margin: 0 8px;
}

.cloud-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFB905;
  margin: 0 8px;
}

.sas-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00d56e;
  margin: 0 8px;

}
