.asset-drop-info {
  font: normal normal 300 12px/40px Inter, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
  /* border-bottom: 0.5px solid #d8d8d8; */
}

.confirm-azimuth {
  border: 3px solid #0059b2;
  color: #0059b2;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
}

.reset-azimuth {
  border: 3px solid #f40000;
  color: #f40000;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
}

.plus-icon {
  background-color: #0059b2;
  color: #fff;
  border-radius: 12px 12px 0px 0px;
  height: 40px;
  width: 60px;
  cursor: pointer;
}

.minus-icon {
  background-color: #0059b2;
  color: #fff;
  border-radius: 0px 0px 12px 12px;
  height: 40px;
  width: 60px;
  cursor: pointer;
}

.azimuth-label {
  height: 40px;
  width: 60px;
  font: normal normal 400 12px/40px Inter, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  background-color: #fff;
  border: 2px solid #0059b2;
}

.sector-icon {
  font-size: 21px;
  color: #eef3f7;
  -webkit-text-stroke-color: #0073ff;
  -webkit-text-stroke-width: 1.5px;
  cursor: default;
}

.active-sector-icon {
  font-size: 22px;
  color: #0073ff;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1.5px;
  text-shadow: 0px 3px 3px #00000029;
  cursor: default;
}

.image-box {
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px dashed #cccccc;
  border-radius: 6px;
  opacity: 1;
  box-shadow: none;
}

.venue-association-box {
  background: #e5eef7 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  box-shadow: none;
}

.association-info {
  font: normal normal 300 10px/20px Inter, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.border-blue {
  border : 1px solid #91CAFF;
  border-radius : 8px;
  display: flex;
}

.upload-count {
  color: #00000073;
  font-size: 11px;
  line-height: 22px;
}