.user-tablebody {
  display: grid;
  /* margin-top: 10px; */
  width: 100%;
  overflow: hidden auto;
  max-height: calc(100vh - 300px);
  min-height: 150px;
}

.user-tablebody tr {
  border-top: 0.5px solid #ededf0;
}

.user-search {
  position: absolute;
  width: 305px;
  background-color: #ffffff;
  z-index: 99999;
  left: 30px;
  max-height: calc(70vh);
  overflow: hidden scroll;
  margin: 10px 0px;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0px 3px 10px 0px #00000052;
}

.user-search-row {
  border-bottom: 1px solid #ededf0;
  cursor: pointer;
}

.user-search-title {
  color: #ff9445;
}
.height-table {
  overflow-y: auto;
  height: calc(100vh - 240px);
}
.pagination li a{
  background-color: #F9FAFC;
  border: none;
  border-radius: 6px;
}
.table-header{
  font : normal normal 600 12px/22px Inter, serif;
  color: #000000E0;
}
.table-body{
  font : normal normal 400 12px/22px Inter, serif;
  color: #000000E0;
}