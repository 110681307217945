.indoor-details {
  background-color: #e5eef7;
  padding: 10px;
  border-radius: 8px;
}

.indoor-heading {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: #0059b2;
  text-transform: uppercase;
}
.indoor-sub-heading {
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: #696969;
}
.indoor-value {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #000000;
  line-height: 22px;
}

.breaker {
  border-top: 1px solid #aaa;
  margin-bottom: 10px;
  margin-top: 10px;
}

.image-box-site {
  min-height: 100px;
  width: 140px;
  cursor: pointer;
}

.image-list-label {
  font: normal normal normal 8px/15px Inter, sans-serif;
  color: #737373;
}

.image-title {
  font: normal normal 600 12px/18px Inter, sans-serif;
  color: #353435;
}
