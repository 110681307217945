.template {
  font: normal normal normal 14px/20px Inter, sans-serif;
  color: #0059b2;
  text-decoration: underline;
}

.file-dropzone:focus {
  border-color: #0059b25e;
  color: #0059b2;
  background-color: #e5eef7;
}

.file-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-width: 2px;
  border-radius: 12px;
  border-color: #cccccc;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #0000008a;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 200px;
}

.upload-icon {
  font-size: 90px;
}

.error-description {
  font: normal normal normal 12px/16px Inter, sans-serif;
  color: #f44545;
}

.count-details {
  font: normal normal normal 12px/16px Inter, sans-serif;
  color: #000000;
}

.error-container {
  padding: 0px 76px;
}

.success-message {
  font: normal normal normal 12px/16px Inter, sans-serif;
  color: #008000;
  text-align: center;
}

.success-count {
  font: normal normal normal 12px/16px Inter, sans-serif;
  color: #000000;
  text-align: center;
}
