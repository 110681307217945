.secondary-button {
  /* background: #0059b21a 0% 0% no-repeat padding-box; */
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px !important;
  min-height: 32px !important;
  cursor: pointer;
  padding: 5px 15px;
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000E0;
  border : 1px solid #D5D6D7;
}

.secondary-button-disabled {
  /* background: #ededf0 0% 0% no-repeat padding-box; */
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  min-height: 32px !important;
  cursor: not-allowed;
  padding: 5px 15px;
  text-align: center;
  font: normal normal 400 12px/16px Inter, sans-serif;
  letter-spacing: 0px;
  color: #000000E0;
  border : 1px solid #D5D6D7;

}

.secondary-delete-button {
  background: rgba(226, 43, 43, 0.1) 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  min-height: 32px !important;
  cursor: pointer;
  padding: 0px 24px;
  font: normal normal 400 12px/22px Inter, sans-serif;
  letter-spacing: 0px;
  color: rgb(226, 43, 43);
  text-align: center;
}
.logout-btn{
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  min-height: 32px !important;
  cursor: pointer;
  padding: 0px 24px;
  font: normal normal 400 12px/22px Inter, sans-serif;
  letter-spacing: 0px;
  color: rgb(226, 43, 43);
  text-align: center;
  border : 1px solid #D5D6D7;
}
